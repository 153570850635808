import React from "react";
import Index from "../../Index";
import { Link, useLocation } from "react-router-dom";
import { List, ListItem } from "@mui/material";
import Svg from "../../../assets/Svg";

export default function Sidebar({ drawerOpen, setDrawerOpen }) {
  const pathname = useLocation();

  const removeClass = () => {
    setDrawerOpen((e) => !e);
    document.body.classList[drawerOpen ? "add" : "remove"]("body-overflow");
    document
      .getElementById("admin-sidebar")
      .classList[drawerOpen ? "add" : "remove"]("active");
  };

  return (
    <>
      <Index.Box className="admin-sidebar-main scrollbar" id={"admin-sidebar"}>
        <Index.Box className="admin-sidebar-inner-main">
          <Link to="/admin" className="redirect-home-link">
            <Index.Box className="admin-sidebar-logo-main admin-side-logo-text">
              <img
                src={Index.Png.yesPayLogo}
                className="admin-sidebar-logo"
                alt="logo"
              />
              {/* <Index.Typography
                 variant="span"
                 component="span"
                 class="logo-text"
               >
                 Farmar Bright Coin
               </Index.Typography> */}
              <Index.Box className="cross-res">
                <Index.Button onClick={removeClass}>
                  {" "}
                  <img
                    src={Index.Png.cancel}
                    className="close-icon-modal"
                    alt="cross"
                  />
                  img
                </Index.Button>
              </Index.Box>
            </Index.Box>
          </Link>
          <Index.Box className="admin-sidebar-list-main">
            <List className="admin-sidebar-list">
              <ListItem className="admin-sidebar-listitem">
                <Link
                  // to="/admin/dashboard"
                  to="/admin"
                  className={
                    pathname?.pathname === "/admin/dashboard"
                      ? "admin-sidebar-link active"
                      : "dashboard-header"
                  }
                  onClick={() => {
                    window.innerWidth <= 768 && removeClass();
                  }}
                >
                   <Index.Box className="admin-sidebar-icons">
                   <img
                      src={Svg.dashboard}
                      className="admin-sidebar-icon"
                    />
                  </Index.Box>
                  Dashboard
                </Link>
              </ListItem>
              <ListItem className="admin-sidebar-listitem">
                <Link
                  // to="/admin/transaction-list"
                  to="/admin/transaction-list"
                  className={
                    pathname?.pathname === "/admin/transaction-list"
                      ? "admin-sidebar-link active"
                      : "dashboard-header"
                  }
                  // className={"dashboard-header"}
                  onClick={() => {
                    window.innerWidth <= 768 && removeClass();
                  }}
                >
                  <Index.Box className="admin-sidebar-icons">
                    <img
                      src={Index.Png.transaction}
                      className="admin-sidebar-icon"
                    />
                  </Index.Box>
                  Transaction List
                </Link>
              </ListItem>

              {/* <ListItem className="admin-sidebar-listitem">
                <Link
                  to="/admin/setting"
                  // to="/admin"
                  className={
                    pathname?.pathname === "/admin/setting"
                      ? "admin-sidebar-link active"
                      : "dashboard-header"
                  }
                  // className={"dashboard-header"}
                  onClick={() => {
                    window.innerWidth <= 768 && removeClass();
                  }}
                >
                   <Index.Box className="admin-sidebar-icons">
                    <img
                      src={Index.Png.settings}
                      className="admin-sidebar-icon"
                    />
                  </Index.Box>
                  Setting
                </Link>
              </ListItem> */}

              <ListItem className="admin-sidebar-listitem">
                <Link
                  // to="/admin/setting"
                  to="/admin/chainList"
                  className={
                    pathname?.pathname === "/admin/chainList"
                      ? "admin-sidebar-link active"
                      : "dashboard-header"
                  }
                  // className={"dashboard-header"}
                  onClick={() => {
                    window.innerWidth <= 768 && removeClass();
                  }}
                >
                  <Index.Box className="admin-sidebar-icons">
                    <img
                      src={Index.Png.token}
                      className="admin-sidebar-icon"
                    />
                  </Index.Box>
                  Chain & Token configuration Master
                </Link>
              </ListItem>
              <ListItem className="admin-sidebar-listitem">
                <Link
                  // to="/admin/setting"
                  to="/admin/merchant-user-list"
                  className={
                    pathname?.pathname === "/admin/merchant-user-list"
                      ? "admin-sidebar-link active"
                      : "dashboard-header"
                  }
                  // className={"dashboard-header"}
                  onClick={() => {
                    window.innerWidth <= 768 && removeClass();
                  }}
                >
                   <Index.Box className="admin-sidebar-icons">
                    <img
                      src={Svg.waitListUser}
                      className="admin-sidebar-icon"
                    />
                  </Index.Box>
                  Merchant User List
                </Link>
              </ListItem>
              <ListItem className="admin-sidebar-listitem">
                <Link
                  to="/admin/user-wallet-list"
                  className={
                    pathname?.pathname === "/admin/user-wallet-list"
                      ? "admin-sidebar-link active"
                      : "dashboard-header"
                  }
                  onClick={() => {
                    window.innerWidth <= 768 && removeClass();
                  }}
                >
                   <Index.Box className="admin-sidebar-icons">
                    <img
                      src={Svg.waitListUser}
                      className="admin-sidebar-icon"
                    />
                  </Index.Box>
                  User Wallet List
                </Link>
              </ListItem>
            </List>
          </Index.Box>
          
        </Index.Box>
      </Index.Box>
    </>
  );
}
