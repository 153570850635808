import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import { DataService } from "../../../../config/DataService";
import API from "../../../../config/API";
import { useSelector } from "react-redux";

export default function UserWalletList() {
    const token = useSelector((state) => state.admin.adminToken);

    const [userWalletList, setUserWalletList] = useState([]);
    const [filterData, setFilterData] = useState([]);
    const [searchVal, setSearchVal] = useState("");
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(15);
    const [loader, setLoader] = useState(false);
    const [count, setCount] = useState(0);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const getUserWalletList = async () => {
        setLoader(true);
        const params = new URLSearchParams({
            search: searchVal,
            pageNumber: page + 1,
            pageSize: rowsPerPage
        });
        try {
            const res = await DataService(token).get(`${API.Admin.GET_USER_WALLET_LIST}?${params.toString()}`);
            if (res?.data?.status === 200) {
                setUserWalletList(res?.data?.data);
                setFilterData(res?.data?.data);
                setCount(res?.data?.totalRecords || 0);
            } 
        } catch (error) {
            console.log(error, 62);
        } finally {
            setLoader(false);
        }
    };
    
    useEffect(() => {
        getUserWalletList();
    }, [page, rowsPerPage, searchVal]);

    const handleSearch = (event) => {
        setSearchVal(event?.target?.value);
        setPage(0);
      };

    return (
        <div style={{ position: "relative" }}>
            {loader && (
                <div>
                    <Index.Box className="loader-main">
                        <img src={Index.Png.loader} className="loader-img" />
                    </Index.Box>
                </div>
            )}
            <div className={loader ? "loader-overlay" : ""}>
                <Index.Box className="dashboard-content bg-white add-user-container transaction-wrap">
                    <Index.Box className="user-head-title">
                        <Index.Typography
                            className="admin-page-title user-list-page-title res-admin-page-title-set"
                            component="h2"
                            variant="h2"
                        >
                            User Wallet List
                        </Index.Typography>
                        <Index.Box className="preasale-search">
                            <Index.Box className="user-search-main cus-search">
                            <Index.Box className="user-search-box">
                                <Index.Box className="form-group">
                                <Index.TextField
                                    fullWidth
                                    id="fullWidth"
                                    className="form-control"
                                    value={searchVal}
                                    placeholder="Search..."
                                    onChange={(e) => handleSearch(e)}
                                />
            
                                <img
                                    src={Index.Svg.search}
                                    className="search-grey-img"
                                    alt="search grey img"
                                ></img>
                                </Index.Box>
                            </Index.Box>
                            </Index.Box>
                        </Index.Box>

                    </Index.Box>
                    <Index.Box className="admin-dashboard-list-row">
                        <Index.Box sx={{ width: 1 }} className="grid-main">
                            <Index.Box
                                display="grid"
                                className="display-row"
                                gridTemplateColumns="repeat(12, 1fr)"
                                gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
                            >
                                <Index.Box
                                    gridColumn={{
                                        xs: "span 12",
                                        sm: "span 12",
                                        md: "span 12",
                                        lg: "span 12",
                                    }}
                                    className="grid-column"
                                >
                                    <Index.Box className="merchant-table-main">
                                        <Index.Box className="page-table-main table-design-main merchant-user-table-main ">
                                            <Index.TableContainer
                                                component={Index.Paper}
                                                className="table-container"
                                            >
                                                <Index.Table
                                                    sx={{ minWidth: 650 }}
                                                    aria-label="simple table"
                                                    className="table"
                                                >
                                                    <Index.TableHead className="table-head">
                                                        <Index.TableRow className="table-row">
                                                            <Index.TableCell
                                                                component="th"
                                                                variant="th"
                                                                className="table-th"
                                                                align="right"
                                                            >
                                                                No.
                                                            </Index.TableCell>
                                                            <Index.TableCell
                                                                component="th"
                                                                variant="th"
                                                                className="table-th"
                                                                align="right"
                                                            >
                                                                Wallet Address
                                                            </Index.TableCell>
                                                            <Index.TableCell
                                                                component="th"
                                                                variant="th"
                                                                className="table-th"
                                                                align="right"
                                                            >
                                                                Created Date Time
                                                            </Index.TableCell>
                                                        </Index.TableRow>
                                                    </Index.TableHead>

                                                    <Index.TableBody className="table-body">
                                                        {userWalletList.length > 0 ? (
                                                            filterData?.map((row, index) => {
                                                                const currentIndex =
                                                                    page * rowsPerPage + index + 1;
                                                                return (
                                                                    <Index.TableRow
                                                                        key={index}
                                                                        sx={{
                                                                            "&:last-child td, &:last-child th": {
                                                                                border: 0,
                                                                            },
                                                                        }}
                                                                    >
                                                                        <Index.TableCell
                                                                            component="td"
                                                                            variant="td"
                                                                            scope="row"
                                                                            className="table-td"
                                                                        >
                                                                            {currentIndex}
                                                                        </Index.TableCell>
                                                                        <Index.TableCell
                                                                            component="td"
                                                                            variant="td"
                                                                            scope="row"
                                                                            className="table-td"
                                                                        >
                                                                            {row?.businessWalletId
                                                                                ? row?.businessWalletId
                                                                                : "-"}
                                                                        </Index.TableCell>
                                                                        <Index.TableCell
                                                                            component="td"
                                                                            variant="td"
                                                                            scope="row"
                                                                            className="table-td"
                                                                        >
                                                                            {row?.createdAt ? Index.moment(
                                                                                row?.createdAt
                                                                                ).format("DD-MM-YYYY HH:mm:ss")
                                                                            : "-"}
                                                                        </Index.TableCell>
                                                                    </Index.TableRow>
                                                                );
                                                            })
                                                        ) : (
                                                            <Index.TableRow>
                                                                <Index.TableCell
                                                                    component="td"
                                                                    variant="td"
                                                                    scope="row"
                                                                    className="table-td"
                                                                    colSpan={7}
                                                                >
                                                                    No Record found
                                                                </Index.TableCell>
                                                            </Index.TableRow>
                                                        )}
                                                    </Index.TableBody>
                                                </Index.Table>
                                            </Index.TableContainer>
                                        </Index.Box>
                                    </Index.Box>{" "}
                                    <Index.Box className="pagination-main">
                                        <Index.TablePagination
                                            component="div"
                                            page={page}
                                            // count={filterData?.length}
                                            count={count}
                                            onPageChange={handleChangePage}
                                            rowsPerPage={rowsPerPage}
                                            rowsPerPageOptions={[15, 25, 50]}
                                            onRowsPerPageChange={handleChangeRowsPerPage}
                                            labelDisplayedRows={(page) =>
                                                `Records ${page.from} to ${page.to === -1 ? page.count : page.to
                                                } of ${page.count}`
                                            }
                                        />
                                    </Index.Box>
                                </Index.Box>
                            </Index.Box>
                        </Index.Box>
                    </Index.Box>
                </Index.Box>
            </div>
        </div>
    );
}
