const API = {
  Admin: {
    ADMIN_LOGIN: "/admin/login",
    FORGOT_PASSWORD: "/admin/forgot-password",
    ADMIN_RESET_PASSWORD: "/admin/reset-password",
    GET_TOKEN_CATEGORY_LIST: "/admin/get-list-category",
    GET_LIST_TOKEN: "/admin/get-list-token",
    CATEGORY_ACTIVE_DEACTIVE: "/admin/category-active-deactive",
    TOKEN_ACTIVE_DEACTIVE: "/admin/token-active-deactive",
    GET_VIEW_TOKEN: "/admin/get-token",
    GET_MERCHANT_USER_LIST: "/admin/get-merchant-user-list",
    GET_DETAILS: "/admin/get-detail",
    USER_ACTIVE_DEACTIVE: "/admin/user-deactivete-activate",
    GET_token_LIST: "/admin/get-token-list",
    // GET_MERCHANT_BUSINESS_LIST:"/admin/get-merchant-business-list"
    SEARCH_USER_LIST: "/admin/search-user",
    CHANGE_TOKEN_IMAGE: "/admin/change-token-image",
    GET_USER_WALLET_LIST: "/admin/get-wallet-list",
  },
  User: {
    GET_ACTIVE_LIST: "user/active-token-list",
    TOKEN_ACTIVE_DEACTIVE: "user/token-add-update",
  },
  Common: {},
};

export default API;
